// src/components/TripCard.js
import React from 'react';

const TripCard = ({ imageUrl, title, dates, description, price, agencyLogo, agencyName, badge }) => (
  <div className="trip-card">
    {/* Imagem e Badge */}
    <div className="trip-image-container">
      {badge && <span className="badge destaque-badge">{badge}</span>}
      <img src={imageUrl} alt={title} className="trip-image" />
    </div>
    
    {/* Conteúdo do Card */}
    <div className="trip-content">
      <div className="trip-info">
        <h3 className="trip-title">{title}</h3>
        <p className="trip-dates"><i className="fa fa-calendar"></i> {dates}</p>
        <p className="trip-description"><i className="fa fa-tag"></i> {description}</p>
      </div>
      <div className="trip-price">
        <span>a partir de</span>
        <span className="price">{price}</span>
        <div className="agency-info">
          <img src={agencyLogo} alt="Logo da Agência" className="agency-logo" />
          <span className="agency-name">{agencyName}</span>
        </div>
      </div>
    </div>

    {/* Botão de Detalhes */}
    <button className="trip-button">Ver Detalhes</button>
  </div>
);

export default TripCard;
