// src/components/Footer.js
import React from 'react';

const Footer = () => (
  <footer className="footer">
    <p>Contate-nos | Termos e Condições | Política de Privacidade</p>
    <p>Siga-nos: Facebook | Instagram | Twitter</p>
  </footer>
);

export default Footer;
