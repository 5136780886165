// src/components/Header.js
import React from 'react';

const Header = () => (
  <header className="header">
    <div className="logo">
      <img src="/assets/images/logo.svg" alt="Viaje-se Logo" className="logo-img" />
    </div>
    <nav className="navigation">
      <a href="#" className="mx-2">Blog</a>
      <a href="#" className="mx-2">Agências</a>
      <a href="#" className="mx-2">Sobre nós</a>
    </nav>
  </header>
);

export default Header;
